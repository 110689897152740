@charset "UTF-8";
/*!
Theme Name: digitalist
Author: Shusaku Yanagi
Version: 1.1
*/




//------------------------------------------
//------------------------------------------
//------------------------------------------
//reset
//------------------------------------------
//------------------------------------------
//------------------------------------------


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}


a {
    text-decoration: none;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote {

    &:before,
    &:after {
        content: '';
        content: none;
    }
}

q {

    &:before,
    &:after {
        content: '';
        content: none;
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
}

.clearfix:after {
    display: block;
    content: "";
    clear: both;
}

//------------------------------------
//------------------------------------
//------------------------------------
//font
//------------------------------------
//------------------------------------
//------------------------------------

@import url('https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i');


//------------------------------------------
//------------------------------------------
//------------------------------------------
//variable
//------------------------------------------
//------------------------------------------
//------------------------------------------



$fs:1.4rem;
$mar:2.5rem;
$color:#ffffff;


//------------------------------------------
//------------------------------------------
//------------------------------------------
//default
//------------------------------------------
//------------------------------------------
//------------------------------------------




$breakpoints: ('sp': 'screen and (min-width: 480px)',
'tl': 'screen and (min-width: 680px)',
'pc': 'screen and (min-width: 1024px)',
) !default;

@mixin mq($breakpoint: pc) {
    @if $breakpoint==sp or $breakpoint==tl or $breakpoint==pc {
        @media #{map-get($breakpoints, $breakpoint)} {
            @content;
        }
    }

    @else {
        @media screen and (min-width: #{$breakpoint}) {
            @content;
        }
    }
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }

    @-moz-keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($animation-name) {
    -webkit-animation: #{$animation-name};
    -moz-animation: #{$animation-name};
    animation: #{$animation-name};
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    animation-fill-mode: both;
}

@function ratio($val, $ratio, $round: false) {
    $result: $val * $ratio;

    @if $round==true {
        $result: round($result);
    }

    @return $result;
}

$ratio-golden: 1.618;
$ratio-golden-rev: 1/$ratio-golden;
$ratio-silver: 1.41421356237;
$ratio-silver-rev: 1/$ratio-silver;

@mixin weight($weight:m) {
    @if $weight==s {
        font-weight: 400;
    }

    @else if $weight==m {
        font-weight: 500;
    }

    @else if $weight==l {
        font-weight: 800;
    }

}

@mixin size($size:xs) {
    @if $size==xxs {
        font-size: .87rem;
    }

    @else if $size==xs {
        font-size: 1.4rem;
    }

    @else if $size==s {
        font-size: 1.87rem;
    }

    @else if $size==m {
        font-size: 1.98rem;
    }

    @else if $size==l {
        font-size: 2.27rem;
    }

    @else if $size==xl {
        font-size: 2.42rem;
    }

    @else if $size==xxl {
        font-size: 2.8rem;
    }

    @else if $size==xxl {
        font-size: 3.38rem;
    }


}

//------------------------------------
//------------------------------------
//------------------------------------
//animation
//------------------------------------
//------------------------------------
//------------------------------------


@include keyframes(front_scroll) {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

@include keyframes(white) {
    0% {
        fill-opacity: 0;
    }

    100% {
        fill-opacity: 1;
    }
}

@include keyframes(circle) {
    0% {
        fill-opacity: 0;
    }

    100% {
        fill-opacity: 1;
    }
}

@include keyframes (dashed) {
    0% {}

    100% {
        stroke-dashoffset: 0;
    }
}

@include keyframes (dashfill) {
    0% {
        stroke-width: .3;
    }

    100% {
        fill-opacity: 1;
        stroke-width: 0;
    }
}

@include keyframes (text) {
    0% {
        fill-opacity: 0;
    }

    100% {
        fill-opacity: 1;
    }
}

@include keyframes (outerline) {
    0% {
        stroke-opacity: 0;
    }

    30% {
        stroke-opacity: 1;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-opacity: 1;
    }
}

@include keyframes (noise) {
    0% {
        opacity: 1;
    }

    10% {
        opacity: .35;
    }

    80% {
        opacity: .35;
    }

    100% {
        opacity: .015;
    }
}

@include keyframes(lock) {
    0% {
        overflow-y: hidden;
    }

    100% {
        overflow-y: visible;

    }
}

@include keyframes(scroll) {
    0% {
        transform: translateY(30%);
    }

    100% {
        transform: translateY(-30%);
    }
}

//------------------------------------------
//------------------------------------------
//------------------------------------------
// common
//------------------------------------------
//------------------------------------------
//------------------------------------------

html {
    font-size: 10px;
    height: 100%;

    @include mq(tl) {
        font-size: 10.5px;
    }

    @include mq() {
        font-size: 11px;
    }
}

body {
    text-rendering: optimizeSpeed;
    font-family: 'Avenir', 'M Plus 1p', "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
    margin: 0;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.8;
    height: 100%;
    color: #fff;
    background: hsl(208, 100%, 1.5%);
    height: 100%;
    letter-spacing: .2em;


    &::before {
        top: 0;
        position: fixed;
        content: "";
        opacity: .015;
        height: 100%;
        width: 100%;
        z-index: -5000000;
        display: block;
        background: repeat url("https://img.gifmagazine.net/gifmagazine/images/1940522/original.gif");
        animation: noise 10.5s cubic-bezier(0.7, 0.3, 1, 1) 0s 1 normal forwards;

    }

    &.access::before {
        animation: none;

    }
}


.link:visited,
.link:link {
    color: #fff;
    position: relative;
    @include size(s);
    @include weight(l);

    &::after {
        position: absolute;
        content: "";
        background: #fff;
        top: 100%;
        height: .1rem;
        width: calc(100% - .8rem);
        left: .4rem;

    }
}


h2 {
    @include size(xl);
    @include weight(l);
    font-style: oblique;

    margin: 0 $mar 14.14rem;

    @include mq(tl) {
        max-width: 100rem;
        width: calc(100% - #{2*$mar});
        margin: 0 auto 14.14rem;

    }
}

#cookieclear {
    color: #fff;
}

section {
    padding: 7rem 0;
}

.hamburger {
    display: none;
    z-index: 100;
    position: fixed;
    top: 3.24rem;
    right: 2rem;
    width: 4.85rem;
    height: 3rem;

    &__inner {
        width: 100%;
        height: 100%;
        position: relative;

        &__bar {
            height: .5rem;
            width: 100%;
            background: #fff;
            position: absolute;
            transition: all .5s ease-in-out;
            border-radius: 50rem;

            &:nth-of-type(1) {
                top: 0;
                transform-origin: center center;

                .active & {
                    top: 50%;
                    transform-origin: center center;
                    transform: translateY(-50%) rotate(225deg);


                }
            }

            &:nth-of-type(2) {
                top: 50%;
                transform: translateY(-50%) rotate(0deg);
                opacity: 1;

                .active & {
                    transform: rotate(-90deg);
                    opacity: 0;
                }
            }

            &:nth-of-type(3) {
                bottom: 0;

                .active & {
                    bottom: 50%;
                    transform: translateY(50%) rotate(135deg);
                }
            }
        }
    }

}

.nav {
    position: fixed;
    z-index: 50;
    width: 100vw;
    top: 50%;
    bottom: 50%;
    overflow-y: hidden;
    background: rgba(0, 8, 34, 0.65);
    transition: all 1s;

    &.active {
        top: 0;
        bottom: 0;
    }

    &__ul {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);

        &__li {
            margin: 2rem 0;
            text-align: center;

            &__a {
                display: block;
                opacity: 0;
                color: #fff;
                padding: 1rem 0;
                background: #014772;
                transition: all 1.5s;
                transform: rotateX(180deg);
                @include size(s);

                .active & {
                    opacity: 1;
                    transform: rotateX(360deg);

                    &:hover {
                        background: #fff;
                        color: #000;
                    }
                }
            }
        }
    }
}


//------------------------------------
//------------------------------------
//------------------------------------
//header
//------------------------------------
//------------------------------------
//------------------------------------


.header {
    position: relative;
    height: 100%;
    margin-bottom: 10rem;

    &__scroll {
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);

        display: none;


        &__i {
            animation: scroll 1.5s ease-in-out 0s infinite alternate;
            color: darken(#fff, 10%);
            font-size: 5rem;
        }
    }
}

//------------------------------------------
//------------------------------------------
//------------------------------------------
// front svg
//------------------------------------------
//------------------------------------------
//------------------------------------------

svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translatex(-50%);
    height: 100 / 3 + 0%;
    display: block;
    transform: translate(-50%, -50%);

    #circle .st0 {
        fill-opacity: 0;
    }

    #Dot .st1,
    #line .st1 {
        fill-opacity: 0;
        animation: circle 1s ease-in-out 8.5s 1 normal forwards;

        .access & {
            fill-opacity: 1;
            animation: none;
        }
    }

    #whiteline .st0 {
        stroke-width: .3;
        stroke-opacity: .7;
        fill-opacity: 0;
        animation: dashed 4s ease-in-out 3.5s 1 normal forwards, dashfill 1s ease-in-out 8.2s 1 normal forwards;
        stroke: #fff;

        .access & {
            stroke-width: 0;
            fill-opacity: 1;
            animation: none;
        }
    }

    .st2 {
        animation: outerline 2s ease-in-out 6.5s 1 normal forwards;

        .access & {
            stroke-opacity: 1;
            stroke-dashoffset: 0 !important;
            animation: none;

        }
    }

    #text .st0 {
        fill-opacity: 0;
    }

    $i:1;

    @while $i <=14 {

        #circle .st0:nth-child(#{$i}) {
            animation: circle 1s ease-in-out #{($i - 1)/14 + 6.5}s 1 normal forwards;

            .access & {
                fill-opacity: 1;
                animation: none;
            }
        }

        $i: $i + 1;
    }

    $i:1;

    @while $i <=10 {
        #text .st0:nth-child(#{$i}) {
            animation: circle 0.3s ease-in-out #{($i - 1) / 5 + 2}s 1 normal forwards;

            .access & {
                fill-opacity: 1;
                animation: none;
            }
        }


        $i: $i + 1;
    }
}



//------------------------------------------
//------------------------------------------
//------------------------------------------
// front contents
//------------------------------------------
//------------------------------------------
//------------------------------------------


.front {
    &__content {
        line-height: 2;
        font-size: $fs;
        @include weight(s);
        color: darken($color, 10%);
    }

    &__concept {

        &__img {
            display: block;

            margin-bottom: 10rem;

            @include mq(tl) {
                max-width: 100rem;
                width: calc(100% - #{2*$mar});
                margin: 0 auto 10rem;

            }
        }

        &__content {
            margin: 0 $mar;

            @include mq(tl) {
                max-width: 100rem;
                width: calc(100% - #{2*$mar});
                margin: 0 auto;

            }
        }

    }

    &__service {
        &__content {
            &__ul {
                padding: 2.5rem;

                @include mq(tl) {
                    max-width: 100rem;
                    width: calc(100% - #{2*$mar});
                    margin: 0 auto;

                }

                @include mq() {}

                &__li {

                    transition: all 1s ease;

                    &:hover {
                        transform: scale(1.1) !important;
                    }

                    margin-bottom: 10rem;

                    a {
                        color: #fff;
                    }

                    @include mq(pc) {
                        vertical-align: top;
                        margin: 0;
                        width: calc(50% - 1.5rem);
                        float: left;
                        margin-bottom: 5rem;

                    }

                    &:nth-of-type(2n+1) {
                        @include mq() {
                            margin-right: 3rem;
                            clear: both;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;

                        @include mq() {
                            margin-bottom: none;
                        }
                    }

                    &__left {
                        float: left;
                        width: 23.6%;

                        @include mq() {
                            width: 100%;
                            float: none;
                        }

                        &__icon {
                            margin-right: 2.5rem;

                            @include mq() {
                                width: 5rem;
                                margin: 0 auto;
                            }
                        }

                    }

                    &__right {
                        float: left;
                        width: 76.4%;

                        @include mq() {
                            float: none;
                            width: 100%;
                        }


                        &__h3 {
                            @include size(xxl);
                            @include weight(m);
                            margin: .5rem 0 6.18rem;

                            @include mq(tl) {
                                @include size(m);
                                text-align: center;
                            }
                        }

                    }

                }
            }
        }
    }

    &__works {
        padding: 5rem 0;





        &__img {
            display: block;
            margin-bottom: 10rem;

            @include mq(tl) {
                max-width: 100rem;
                width: calc(100% - #{2*$mar});
                margin: 0 auto 10rem;

            }

        }

        &__content {
            margin: 0 $mar;

            @include mq(tl) {
                max-width: 100rem;
                width: calc(100% - #{2*$mar});
                margin: 0 auto;

            }
        }

    }

}

//------------------------------------------
//------------------------------------------
//------------------------------------------
//works
//------------------------------------------
//------------------------------------------
//------------------------------------------


.works {

    &__div {
        z-index: 1;

        &__ul {
            z-index: 1;

            &__li {
                position: relative;
                margin: 20rem 0;
                text-shadow: 1px 1px 15px #000;
                transition: all 1s ease-in-out;


                &::before {
                    content: "";
                    display: block;
                    padding-bottom: 61.8%;

                    @include mq(tl) {
                        padding-bottom: 30.9%;
                    }
                }

                &__photo {
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    bottom: 50%;
                    width: 100%;
                    right: 0;
                    background-position: center center !important;
                    background-size: cover !important;
                    transition: all 1s ease-in-out;


                    @include mq(tl) {
                        top: 69.1%;
                        bottom: 30.9%;
                        width: 50%;
                        left: initial;
                        right: 11.8%;

                    }


                    .active & {
                        top: 0;
                        bottom: 0;

                        @include mq(tl) {
                            top: 19.1%;
                            bottom: -20.9%;
                        }
                    }



                    &::after {
                        position: absolute;
                        content: "";
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                        background: hsla(0, 0%, 12%, 0.4);


                    }

                    &.active {
                        padding: 5rem;
                    }
                }

                &__inner {
                    z-index: 5;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 100%;

                    &__h3 {
                        transition: all 1s ease-in-out;
                        @include size(xxl);
                        z-index: 5;
                        position: relative;
                        margin: 0 $mar;

                        @include mq(tl) {
                            max-width: 100rem;
                            width: calc(100% - #{2*$mar});
                            margin: 0 auto;

                        }

                        @include mq(tl) {

                            line-height: 1;

                            .active & {

                                margin-bottom: 1rem;
                                padding-bottom: 1rem;
                            }

                            &::after {
                                position: absolute;
                                content: "";
                                background: rgba(255, 255, 255, 0.23);
                                bottom: 0;
                                height: .1rem;
                                left: 0;
                                right: 100%;
                                transition: all 1s ease-in-out;

                                .active & {
                                    right: 0;
                                }
                            }

                        }



                    }

                    &__year {
                        color: darken(#fff, 10%);
                        z-index: 5;
                        position: relative;
                        margin: 0 $mar;

                        @include mq(tl) {
                            max-width: 100rem;
                            width: calc(100% - #{2*$mar});
                            margin: 0 auto;

                        }
                    }

                    &__genre {
                        position: relative;
                        display: none;
                        z-index: 5;
                        margin: 0 $mar;

                        @include mq(tl) {
                            max-width: 100rem;
                            width: calc(100% - #{2*$mar});
                            margin: 0 auto;

                        }

                    }

                    &__content {
                        margin: 1rem $mar;
                        color: darken(#fff, 10%);

                        @include mq(tl) {
                            max-width: 100rem;
                            width: calc(100% - #{2*$mar});
                            margin: 0 auto;

                        }
                    }

                    &__icons {
                        margin: 0 $mar;
                        font-size: 2rem;

                        @include mq(tl) {
                            max-width: 100rem;
                            width: calc(100% - #{2*$mar});
                            margin: 0 auto;

                        }


                        &__media {
                            color: darken(#fff, 10%);
                            display: inline-block;
                            margin-riht: 2rem;


                            &__icon {
                                display: inline-block;
                                border-bottom: 1px solid;
                                padding-bottom: 3px;
                                line-height: 1;


                                & a {
                                    color: #fff;

                                }

                            }
                        }

                        &__link {
                            display: inline-block;
                            border-bottom: 1px solid;
                            padding-bottom: 4px;
                            color: darken(#fff, 10%);
                        }
                    }
                }
            }
        }
    }
}

//------------------------------------------
//------------------------------------------
//------------------------------------------
//events
//------------------------------------------
//------------------------------------------
//------------------------------------------


.events {

    &__div {
        z-index: 1;

        &__ul {
            z-index: 1;
            margin-top: 15rem;

            &__li {
                text-shadow: 1px 1px 15px #000;
                transition: all 1s ease-in-out;
                margin-bottom: 15rem;


                &__photo {


                    margin: 0 $mar 5rem;


                    @include mq(tl) {
                        width: calc(100% - #{2*$mar});
                        margin: 0 auto 5rem;
                        max-width: 100rem;


                    }

                    &__inner {
                        width: 50rem;
                        max-width: 100%;
                    }
                }

                &__inner {
                    width: 100%;
                    margin-bottom: 5rem;

                    &__h3 {
                        @include size(xxl);
                        margin: 0 $mar;

                        @include mq(tl) {
                            max-width: 100rem;
                            width: calc(100% - #{2*$mar});
                            margin: 0 auto;

                        }

                        @include mq(tl) {

                            line-height: 1;

                            .active & {

                                margin-bottom: 1rem;
                                padding-bottom: 1rem;
                            }


                        }



                    }

                    &__year {
                        color: darken(#fff, 10%);
                        z-index: 5;
                        position: relative;
                        margin: 0 $mar;

                        @include mq(tl) {
                            max-width: 100rem;
                            width: calc(100% - #{2*$mar});
                            margin: 0 auto;

                        }
                    }

                    &__genre {
                        position: relative;
                        z-index: 5;
                        margin: 0 $mar;

                        @include mq(tl) {
                            max-width: 100rem;
                            width: calc(100% - #{2*$mar});
                            margin: 0 auto;

                        }

                    }

                    &__icons {
                        margin: 0 $mar;
                        font-size: 2rem;

                        @include mq(tl) {
                            max-width: 100rem;
                            width: calc(100% - #{2*$mar});
                            margin: 0 auto;

                        } 


                        &__media {
                            color: darken(#fff, 10%); 
                            display: inline-block;
                            margin-riht: 2rem;


                            &__icon {
                                display: inline-block;
                                border-bottom: 1px solid;
                                padding-bottom: 3px;
                                line-height: 1;  
 

                                & a {
                                    color: #fff;

                                }

                            }
                        }

                        &__link {
                            display: inline-block;
                            border-bottom: 1px solid;
                            padding-bottom: 4px;
                            color: darken(#fff, 10%);
                        }
                    }

                }

                &__content {
                    margin: 0 $mar;

                    @include mq(tl) {
                        max-width: 100rem;
                        width: calc(100% - #{2*$mar});
                        margin: 0 auto;

                    }
                }
            }
        }
    }
}

//------------------------------------------
//------------------------------------------
//------------------------------------------
//footer
//------------------------------------------
//------------------------------------------
//------------------------------------------


.footer {
    background: #000000;
    margin: 0 2.5rem 5 rem;
    text-align: center;

    &__contact {
        padding: 5rem 0;
        margin: 0 $mar;
        border-bottom: .1rem solid darken($color, 50%);

        &__h2 {
            font-style: normal;
            margin: 0 auto 2rem;
        }

        &__mail:link,
        &__mail:visited {
            @include weight(s);
            @include size(xs);
        }
    }

    &__company {
        @include size(xs);
        padding: 5rem 0;

        &__name {
            margin-bottom: 3rem;
            @include weight(l);
            @include size(m);
        }

        &__services {
            margin-bottom: 3rem;
            @include weight(m);

            &__ul {
                &__li {
                    margin-bottom: .5rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

        }

        &__place {
            & div {
                margin-bottom: .5rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &__postal {}

            &__address {}

            &__tel {
                a {
                    color: #fff;
                }
            }
        }
    }
}


//------------------------------------------
//------------------------------------------
//------------------------------------------
//talents
//------------------------------------------
//------------------------------------------
//------------------------------------------


.talents_ul{
  max-width: 75rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  &_li{
    max-width: 25rem;
    margin-bottom: 5rem;
    &_a{
      &_img {
        margin-bottom: 1rem;
        padding: 0 4rem;
      }
      &_sns {
        margin-bottom: 1rem;
        &_a {
          display: inline-block !important;
        }
      }
      &_title {
        font-size: 1.62rem;
        font-weight: 500;
        color: white;
      }
      &_detail{
        color: #fff;
        text-align: right;
      }
      &_eng {
        font-size: 1rem;
        color: #959595;
        margin-bottom: 1.62rem;
      }

    }
  }
}

.talent_cover {
  width: 100%;
  margin-bottom: 5rem; }

.talent_name {
  font-size: 2.4rem;
  display: block;
  max-width: 50rem;
  margin: 0 auto 1rem;}

.talent_sns {
  text-align: center;
  margin-bottom: 1.6rem; }
.talent_sns_a, .talents_ul_li_a_sns_a {
  color: #fff !important;
  border-radius: 50%;
  background: #ff614c;
  font-size: 1.7rem;
  width: 3rem;
  height: 3rem;
  display: inline-block;
  margin-right: 1rem;
  position: relative;
  text-align: center; }
.talent_sns_a:last-child, .talents_ul_li_a_sns_a:last-child {
  margin-right: 0; }
.talent_sns_a_icon, .talents_ul_li_a_sns_a_icon {
  margin-left: 10%;
  vertical-align: middle; }

.talent_content {
  width: calc(100% - 2rem);
  max-width: 50rem;
  margin: 0 auto 10rem; }

.talent_gallery {
  margin: 0 auto;
  width: calc(100% - 2rem);
  max-width: 50rem; }
.talent_gallery_ul_li {
  margin-bottom: 4.24rem;
}

.talent_insta_ul{
  margin: 0 auto;
  width: calc(100% - 2rem);
  max-width: 50rem;
}
.talent_insta_account {
  margin-bottom: 5rem;
  text-align: center; }
.talent_insta_account a.artist_insta_account_link {
  font-size: 1.62rem;
  font-weight: 500;
  display: inline-block;
  border-bottom: 1px solid;
  color: #e83426; }

.talent_insta_account .talent_insta_account_link_span {
  text-decoration: underline;
  color:white;
}

.talent_insta_ul a img {
  width: 100%; }

//------------------------------------------
//------------------------------------------
//------------------------------------------
//video
//------------------------------------------
//------------------------------------------
//------------------------------------------

.YouTubePopUp-Wrap {
    background: #000 !important;

    .Video-PopUp-Content {


        .vp-flex {

            background-color: #0a0a0a !important;
        }
    }
}
