@charset "UTF-8";
/*!
Theme Name: digitalist
Author: Shusaku Yanagi
Version: 1.1
*/
@import url("https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

a {
  text-decoration: none;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

.clearfix:after {
  display: block;
  content: "";
  clear: both;
}

@-webkit-keyframes front_scroll {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@-moz-keyframes front_scroll {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes front_scroll {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes white {
  0% {
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 1;
  }
}

@-moz-keyframes white {
  0% {
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 1;
  }
}

@keyframes white {
  0% {
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 1;
  }
}

@-webkit-keyframes circle {
  0% {
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 1;
  }
}

@-moz-keyframes circle {
  0% {
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 1;
  }
}

@keyframes circle {
  0% {
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 1;
  }
}

@-webkit-keyframes dashed {
  0% {
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-moz-keyframes dashed {
  0% {
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dashed {
  0% {
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dashfill {
  0% {
    stroke-width: .3;
  }
  100% {
    fill-opacity: 1;
    stroke-width: 0;
  }
}

@-moz-keyframes dashfill {
  0% {
    stroke-width: .3;
  }
  100% {
    fill-opacity: 1;
    stroke-width: 0;
  }
}

@keyframes dashfill {
  0% {
    stroke-width: .3;
  }
  100% {
    fill-opacity: 1;
    stroke-width: 0;
  }
}

@-webkit-keyframes text {
  0% {
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 1;
  }
}

@-moz-keyframes text {
  0% {
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 1;
  }
}

@keyframes text {
  0% {
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 1;
  }
}

@-webkit-keyframes outerline {
  0% {
    stroke-opacity: 0;
  }
  30% {
    stroke-opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-opacity: 1;
  }
}

@-moz-keyframes outerline {
  0% {
    stroke-opacity: 0;
  }
  30% {
    stroke-opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-opacity: 1;
  }
}

@keyframes outerline {
  0% {
    stroke-opacity: 0;
  }
  30% {
    stroke-opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-opacity: 1;
  }
}

@-webkit-keyframes noise {
  0% {
    opacity: 1;
  }
  10% {
    opacity: .35;
  }
  80% {
    opacity: .35;
  }
  100% {
    opacity: .015;
  }
}

@-moz-keyframes noise {
  0% {
    opacity: 1;
  }
  10% {
    opacity: .35;
  }
  80% {
    opacity: .35;
  }
  100% {
    opacity: .015;
  }
}

@keyframes noise {
  0% {
    opacity: 1;
  }
  10% {
    opacity: .35;
  }
  80% {
    opacity: .35;
  }
  100% {
    opacity: .015;
  }
}

@-webkit-keyframes lock {
  0% {
    overflow-y: hidden;
  }
  100% {
    overflow-y: visible;
  }
}

@-moz-keyframes lock {
  0% {
    overflow-y: hidden;
  }
  100% {
    overflow-y: visible;
  }
}

@keyframes lock {
  0% {
    overflow-y: hidden;
  }
  100% {
    overflow-y: visible;
  }
}

@-webkit-keyframes scroll {
  0% {
    transform: translateY(30%);
  }
  100% {
    transform: translateY(-30%);
  }
}

@-moz-keyframes scroll {
  0% {
    transform: translateY(30%);
  }
  100% {
    transform: translateY(-30%);
  }
}

@keyframes scroll {
  0% {
    transform: translateY(30%);
  }
  100% {
    transform: translateY(-30%);
  }
}

html {
  font-size: 10px;
  height: 100%;
}

@media screen and (min-width: 680px) {
  html {
    font-size: 10.5px;
  }
}

@media screen and (min-width: 1024px) {
  html {
    font-size: 11px;
  }
}

body {
  text-rendering: optimizeSpeed;
  font-family: 'Avenir', 'M Plus 1p', "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8;
  height: 100%;
  color: #fff;
  background: #000408;
  height: 100%;
  letter-spacing: .2em;
}

body::before {
  top: 0;
  position: fixed;
  content: "";
  opacity: .015;
  height: 100%;
  width: 100%;
  z-index: -5000000;
  display: block;
  background: repeat url("https://img.gifmagazine.net/gifmagazine/images/1940522/original.gif");
  animation: noise 10.5s cubic-bezier(0.7, 0.3, 1, 1) 0s 1 normal forwards;
}

body.access::before {
  animation: none;
}

.link:visited,
.link:link {
  color: #fff;
  position: relative;
  font-size: 1.87rem;
  font-weight: 800;
}

.link:visited::after,
.link:link::after {
  position: absolute;
  content: "";
  background: #fff;
  top: 100%;
  height: .1rem;
  width: calc(100% - .8rem);
  left: .4rem;
}

h2 {
  font-size: 2.42rem;
  font-weight: 800;
  font-style: oblique;
  margin: 0 2.5rem 14.14rem;
}

@media screen and (min-width: 680px) {
  h2 {
    max-width: 100rem;
    width: calc(100% - 5rem);
    margin: 0 auto 14.14rem;
  }
}

#cookieclear {
  color: #fff;
}

section {
  padding: 7rem 0;
}

.hamburger {
  display: none;
  z-index: 100;
  position: fixed;
  top: 3.24rem;
  right: 2rem;
  width: 4.85rem;
  height: 3rem;
}

.hamburger__inner {
  width: 100%;
  height: 100%;
  position: relative;
}

.hamburger__inner__bar {
  height: .5rem;
  width: 100%;
  background: #fff;
  position: absolute;
  transition: all .5s ease-in-out;
  border-radius: 50rem;
}

.hamburger__inner__bar:nth-of-type(1) {
  top: 0;
  transform-origin: center center;
}

.active .hamburger__inner__bar:nth-of-type(1) {
  top: 50%;
  transform-origin: center center;
  transform: translateY(-50%) rotate(225deg);
}

.hamburger__inner__bar:nth-of-type(2) {
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  opacity: 1;
}

.active .hamburger__inner__bar:nth-of-type(2) {
  transform: rotate(-90deg);
  opacity: 0;
}

.hamburger__inner__bar:nth-of-type(3) {
  bottom: 0;
}

.active .hamburger__inner__bar:nth-of-type(3) {
  bottom: 50%;
  transform: translateY(50%) rotate(135deg);
}

.nav {
  position: fixed;
  z-index: 50;
  width: 100vw;
  top: 50%;
  bottom: 50%;
  overflow-y: hidden;
  background: rgba(0, 8, 34, 0.65);
  transition: all 1s;
}

.nav.active {
  top: 0;
  bottom: 0;
}

.nav__ul {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
}

.nav__ul__li {
  margin: 2rem 0;
  text-align: center;
}

.nav__ul__li__a {
  display: block;
  opacity: 0;
  color: #fff;
  padding: 1rem 0;
  background: #014772;
  transition: all 1.5s;
  transform: rotateX(180deg);
  font-size: 1.87rem;
}

.active .nav__ul__li__a {
  opacity: 1;
  transform: rotateX(360deg);
}

.active .nav__ul__li__a:hover {
  background: #fff;
  color: #000;
}

.header {
  position: relative;
  height: 100%;
  margin-bottom: 10rem;
}

.header__scroll {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}

.header__scroll__i {
  animation: scroll 1.5s ease-in-out 0s infinite alternate;
  color: #e6e6e6;
  font-size: 5rem;
}

svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translatex(-50%);
  height: 33.33333%;
  display: block;
  transform: translate(-50%, -50%);
}

svg #circle .st0 {
  fill-opacity: 0;
}

svg #Dot .st1,
svg #line .st1 {
  fill-opacity: 0;
  animation: circle 1s ease-in-out 8.5s 1 normal forwards;
}

.access svg #Dot .st1, .access
svg #line .st1 {
  fill-opacity: 1;
  animation: none;
}

svg #whiteline .st0 {
  stroke-width: .3;
  stroke-opacity: .7;
  fill-opacity: 0;
  animation: dashed 4s ease-in-out 3.5s 1 normal forwards, dashfill 1s ease-in-out 8.2s 1 normal forwards;
  stroke: #fff;
}

.access svg #whiteline .st0 {
  stroke-width: 0;
  fill-opacity: 1;
  animation: none;
}

svg .st2 {
  animation: outerline 2s ease-in-out 6.5s 1 normal forwards;
}

.access svg .st2 {
  stroke-opacity: 1;
  stroke-dashoffset: 0 !important;
  animation: none;
}

svg #text .st0 {
  fill-opacity: 0;
}

svg #circle .st0:nth-child(1) {
  animation: circle 1s ease-in-out 6.5s 1 normal forwards;
}

.access svg #circle .st0:nth-child(1) {
  fill-opacity: 1;
  animation: none;
}

svg #circle .st0:nth-child(2) {
  animation: circle 1s ease-in-out 6.57143s 1 normal forwards;
}

.access svg #circle .st0:nth-child(2) {
  fill-opacity: 1;
  animation: none;
}

svg #circle .st0:nth-child(3) {
  animation: circle 1s ease-in-out 6.64286s 1 normal forwards;
}

.access svg #circle .st0:nth-child(3) {
  fill-opacity: 1;
  animation: none;
}

svg #circle .st0:nth-child(4) {
  animation: circle 1s ease-in-out 6.71429s 1 normal forwards;
}

.access svg #circle .st0:nth-child(4) {
  fill-opacity: 1;
  animation: none;
}

svg #circle .st0:nth-child(5) {
  animation: circle 1s ease-in-out 6.78571s 1 normal forwards;
}

.access svg #circle .st0:nth-child(5) {
  fill-opacity: 1;
  animation: none;
}

svg #circle .st0:nth-child(6) {
  animation: circle 1s ease-in-out 6.85714s 1 normal forwards;
}

.access svg #circle .st0:nth-child(6) {
  fill-opacity: 1;
  animation: none;
}

svg #circle .st0:nth-child(7) {
  animation: circle 1s ease-in-out 6.92857s 1 normal forwards;
}

.access svg #circle .st0:nth-child(7) {
  fill-opacity: 1;
  animation: none;
}

svg #circle .st0:nth-child(8) {
  animation: circle 1s ease-in-out 7s 1 normal forwards;
}

.access svg #circle .st0:nth-child(8) {
  fill-opacity: 1;
  animation: none;
}

svg #circle .st0:nth-child(9) {
  animation: circle 1s ease-in-out 7.07143s 1 normal forwards;
}

.access svg #circle .st0:nth-child(9) {
  fill-opacity: 1;
  animation: none;
}

svg #circle .st0:nth-child(10) {
  animation: circle 1s ease-in-out 7.14286s 1 normal forwards;
}

.access svg #circle .st0:nth-child(10) {
  fill-opacity: 1;
  animation: none;
}

svg #circle .st0:nth-child(11) {
  animation: circle 1s ease-in-out 7.21429s 1 normal forwards;
}

.access svg #circle .st0:nth-child(11) {
  fill-opacity: 1;
  animation: none;
}

svg #circle .st0:nth-child(12) {
  animation: circle 1s ease-in-out 7.28571s 1 normal forwards;
}

.access svg #circle .st0:nth-child(12) {
  fill-opacity: 1;
  animation: none;
}

svg #circle .st0:nth-child(13) {
  animation: circle 1s ease-in-out 7.35714s 1 normal forwards;
}

.access svg #circle .st0:nth-child(13) {
  fill-opacity: 1;
  animation: none;
}

svg #circle .st0:nth-child(14) {
  animation: circle 1s ease-in-out 7.42857s 1 normal forwards;
}

.access svg #circle .st0:nth-child(14) {
  fill-opacity: 1;
  animation: none;
}

svg #text .st0:nth-child(1) {
  animation: circle 0.3s ease-in-out 2s 1 normal forwards;
}

.access svg #text .st0:nth-child(1) {
  fill-opacity: 1;
  animation: none;
}

svg #text .st0:nth-child(2) {
  animation: circle 0.3s ease-in-out 2.2s 1 normal forwards;
}

.access svg #text .st0:nth-child(2) {
  fill-opacity: 1;
  animation: none;
}

svg #text .st0:nth-child(3) {
  animation: circle 0.3s ease-in-out 2.4s 1 normal forwards;
}

.access svg #text .st0:nth-child(3) {
  fill-opacity: 1;
  animation: none;
}

svg #text .st0:nth-child(4) {
  animation: circle 0.3s ease-in-out 2.6s 1 normal forwards;
}

.access svg #text .st0:nth-child(4) {
  fill-opacity: 1;
  animation: none;
}

svg #text .st0:nth-child(5) {
  animation: circle 0.3s ease-in-out 2.8s 1 normal forwards;
}

.access svg #text .st0:nth-child(5) {
  fill-opacity: 1;
  animation: none;
}

svg #text .st0:nth-child(6) {
  animation: circle 0.3s ease-in-out 3s 1 normal forwards;
}

.access svg #text .st0:nth-child(6) {
  fill-opacity: 1;
  animation: none;
}

svg #text .st0:nth-child(7) {
  animation: circle 0.3s ease-in-out 3.2s 1 normal forwards;
}

.access svg #text .st0:nth-child(7) {
  fill-opacity: 1;
  animation: none;
}

svg #text .st0:nth-child(8) {
  animation: circle 0.3s ease-in-out 3.4s 1 normal forwards;
}

.access svg #text .st0:nth-child(8) {
  fill-opacity: 1;
  animation: none;
}

svg #text .st0:nth-child(9) {
  animation: circle 0.3s ease-in-out 3.6s 1 normal forwards;
}

.access svg #text .st0:nth-child(9) {
  fill-opacity: 1;
  animation: none;
}

svg #text .st0:nth-child(10) {
  animation: circle 0.3s ease-in-out 3.8s 1 normal forwards;
}

.access svg #text .st0:nth-child(10) {
  fill-opacity: 1;
  animation: none;
}

.front__content {
  line-height: 2;
  font-size: 1.4rem;
  font-weight: 400;
  color: #e6e6e6;
}

.front__concept__img {
  display: block;
  margin-bottom: 10rem;
}

@media screen and (min-width: 680px) {
  .front__concept__img {
    max-width: 100rem;
    width: calc(100% - 5rem);
    margin: 0 auto 10rem;
  }
}

.front__concept__content {
  margin: 0 2.5rem;
}

@media screen and (min-width: 680px) {
  .front__concept__content {
    max-width: 100rem;
    width: calc(100% - 5rem);
    margin: 0 auto;
  }
}

.front__service__content__ul {
  padding: 2.5rem;
}

@media screen and (min-width: 680px) {
  .front__service__content__ul {
    max-width: 100rem;
    width: calc(100% - 5rem);
    margin: 0 auto;
  }
}

.front__service__content__ul__li {
  transition: all 1s ease;
  margin-bottom: 10rem;
}

.front__service__content__ul__li:hover {
  transform: scale(1.1) !important;
}

.front__service__content__ul__li a {
  color: #fff;
}

@media screen and (min-width: 1024px) {
  .front__service__content__ul__li {
    vertical-align: top;
    margin: 0;
    width: calc(50% - 1.5rem);
    float: left;
    margin-bottom: 5rem;
  }
}

@media screen and (min-width: 1024px) {
  .front__service__content__ul__li:nth-of-type(2n+1) {
    margin-right: 3rem;
    clear: both;
  }
}

.front__service__content__ul__li:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 1024px) {
  .front__service__content__ul__li:last-child {
    margin-bottom: none;
  }
}

.front__service__content__ul__li__left {
  float: left;
  width: 23.6%;
}

@media screen and (min-width: 1024px) {
  .front__service__content__ul__li__left {
    width: 100%;
    float: none;
  }
}

.front__service__content__ul__li__left__icon {
  margin-right: 2.5rem;
}

@media screen and (min-width: 1024px) {
  .front__service__content__ul__li__left__icon {
    width: 5rem;
    margin: 0 auto;
  }
}

.front__service__content__ul__li__right {
  float: left;
  width: 76.4%;
}

@media screen and (min-width: 1024px) {
  .front__service__content__ul__li__right {
    float: none;
    width: 100%;
  }
}

.front__service__content__ul__li__right__h3 {
  font-size: 2.8rem;
  font-weight: 500;
  margin: .5rem 0 6.18rem;
}

@media screen and (min-width: 680px) {
  .front__service__content__ul__li__right__h3 {
    font-size: 1.98rem;
    text-align: center;
  }
}

.front__works {
  padding: 5rem 0;
}

.front__works__img {
  display: block;
  margin-bottom: 10rem;
}

@media screen and (min-width: 680px) {
  .front__works__img {
    max-width: 100rem;
    width: calc(100% - 5rem);
    margin: 0 auto 10rem;
  }
}

.front__works__content {
  margin: 0 2.5rem;
}

@media screen and (min-width: 680px) {
  .front__works__content {
    max-width: 100rem;
    width: calc(100% - 5rem);
    margin: 0 auto;
  }
}

.works__div {
  z-index: 1;
}

.works__div__ul {
  z-index: 1;
}

.works__div__ul__li {
  position: relative;
  margin: 20rem 0;
  text-shadow: 1px 1px 15px #000;
  transition: all 1s ease-in-out;
}

.works__div__ul__li::before {
  content: "";
  display: block;
  padding-bottom: 61.8%;
}

@media screen and (min-width: 680px) {
  .works__div__ul__li::before {
    padding-bottom: 30.9%;
  }
}

.works__div__ul__li__photo {
  position: absolute;
  z-index: 1;
  top: 50%;
  bottom: 50%;
  width: 100%;
  right: 0;
  background-position: center center !important;
  background-size: cover !important;
  transition: all 1s ease-in-out;
}

@media screen and (min-width: 680px) {
  .works__div__ul__li__photo {
    top: 69.1%;
    bottom: 30.9%;
    width: 50%;
    left: initial;
    right: 11.8%;
  }
}

.active .works__div__ul__li__photo {
  top: 0;
  bottom: 0;
}

@media screen and (min-width: 680px) {
  .active .works__div__ul__li__photo {
    top: 19.1%;
    bottom: -20.9%;
  }
}

.works__div__ul__li__photo::after {
  position: absolute;
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(31, 31, 31, 0.4);
}

.works__div__ul__li__photo.active {
  padding: 5rem;
}

.works__div__ul__li__inner {
  z-index: 5;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.works__div__ul__li__inner__h3 {
  transition: all 1s ease-in-out;
  font-size: 2.8rem;
  z-index: 5;
  position: relative;
  margin: 0 2.5rem;
}

@media screen and (min-width: 680px) {
  .works__div__ul__li__inner__h3 {
    max-width: 100rem;
    width: calc(100% - 5rem);
    margin: 0 auto;
  }
}

@media screen and (min-width: 680px) {
  .works__div__ul__li__inner__h3 {
    line-height: 1;
  }
  .active .works__div__ul__li__inner__h3 {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
  .works__div__ul__li__inner__h3::after {
    position: absolute;
    content: "";
    background: rgba(255, 255, 255, 0.23);
    bottom: 0;
    height: .1rem;
    left: 0;
    right: 100%;
    transition: all 1s ease-in-out;
  }
  .active .works__div__ul__li__inner__h3::after {
    right: 0;
  }
}

.works__div__ul__li__inner__year {
  color: #e6e6e6;
  z-index: 5;
  position: relative;
  margin: 0 2.5rem;
}

@media screen and (min-width: 680px) {
  .works__div__ul__li__inner__year {
    max-width: 100rem;
    width: calc(100% - 5rem);
    margin: 0 auto;
  }
}

.works__div__ul__li__inner__genre {
  position: relative;
  display: none;
  z-index: 5;
  margin: 0 2.5rem;
}

@media screen and (min-width: 680px) {
  .works__div__ul__li__inner__genre {
    max-width: 100rem;
    width: calc(100% - 5rem);
    margin: 0 auto;
  }
}

.works__div__ul__li__inner__content {
  margin: 1rem 2.5rem;
  color: #e6e6e6;
}

@media screen and (min-width: 680px) {
  .works__div__ul__li__inner__content {
    max-width: 100rem;
    width: calc(100% - 5rem);
    margin: 0 auto;
  }
}

.works__div__ul__li__inner__icons {
  margin: 0 2.5rem;
  font-size: 2rem;
}

@media screen and (min-width: 680px) {
  .works__div__ul__li__inner__icons {
    max-width: 100rem;
    width: calc(100% - 5rem);
    margin: 0 auto;
  }
}

.works__div__ul__li__inner__icons__media {
  color: #e6e6e6;
  display: inline-block;
  margin-riht: 2rem;
}

.works__div__ul__li__inner__icons__media__icon {
  display: inline-block;
  border-bottom: 1px solid;
  padding-bottom: 3px;
  line-height: 1;
}

.works__div__ul__li__inner__icons__media__icon a {
  color: #fff;
}

.works__div__ul__li__inner__icons__link {
  display: inline-block;
  border-bottom: 1px solid;
  padding-bottom: 4px;
  color: #e6e6e6;
}

.events__div {
  z-index: 1;
}

.events__div__ul {
  z-index: 1;
  margin-top: 15rem;
}

.events__div__ul__li {
  text-shadow: 1px 1px 15px #000;
  transition: all 1s ease-in-out;
  margin-bottom: 15rem;
}

.events__div__ul__li__photo {
  margin: 0 2.5rem 5rem;
}

@media screen and (min-width: 680px) {
  .events__div__ul__li__photo {
    width: calc(100% - 5rem);
    margin: 0 auto 5rem;
    max-width: 100rem;
  }
}

.events__div__ul__li__photo__inner {
  width: 50rem;
  max-width: 100%;
}

.events__div__ul__li__inner {
  width: 100%;
  margin-bottom: 5rem;
}

.events__div__ul__li__inner__h3 {
  font-size: 2.8rem;
  margin: 0 2.5rem;
}

@media screen and (min-width: 680px) {
  .events__div__ul__li__inner__h3 {
    max-width: 100rem;
    width: calc(100% - 5rem);
    margin: 0 auto;
  }
}

@media screen and (min-width: 680px) {
  .events__div__ul__li__inner__h3 {
    line-height: 1;
  }
  .active .events__div__ul__li__inner__h3 {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
}

.events__div__ul__li__inner__year {
  color: #e6e6e6;
  z-index: 5;
  position: relative;
  margin: 0 2.5rem;
}

@media screen and (min-width: 680px) {
  .events__div__ul__li__inner__year {
    max-width: 100rem;
    width: calc(100% - 5rem);
    margin: 0 auto;
  }
}

.events__div__ul__li__inner__genre {
  position: relative;
  z-index: 5;
  margin: 0 2.5rem;
}

@media screen and (min-width: 680px) {
  .events__div__ul__li__inner__genre {
    max-width: 100rem;
    width: calc(100% - 5rem);
    margin: 0 auto;
  }
}

.events__div__ul__li__inner__icons {
  margin: 0 2.5rem;
  font-size: 2rem;
}

@media screen and (min-width: 680px) {
  .events__div__ul__li__inner__icons {
    max-width: 100rem;
    width: calc(100% - 5rem);
    margin: 0 auto;
  }
}

.events__div__ul__li__inner__icons__media {
  color: #e6e6e6;
  display: inline-block;
  margin-riht: 2rem;
}

.events__div__ul__li__inner__icons__media__icon {
  display: inline-block;
  border-bottom: 1px solid;
  padding-bottom: 3px;
  line-height: 1;
}

.events__div__ul__li__inner__icons__media__icon a {
  color: #fff;
}

.events__div__ul__li__inner__icons__link {
  display: inline-block;
  border-bottom: 1px solid;
  padding-bottom: 4px;
  color: #e6e6e6;
}

.events__div__ul__li__content {
  margin: 0 2.5rem;
}

@media screen and (min-width: 680px) {
  .events__div__ul__li__content {
    max-width: 100rem;
    width: calc(100% - 5rem);
    margin: 0 auto;
  }
}

.footer {
  background: #000000;
  margin: 0 2.5rem 5 rem;
  text-align: center;
}

.footer__contact {
  padding: 5rem 0;
  margin: 0 2.5rem;
  border-bottom: 0.1rem solid gray;
}

.footer__contact__h2 {
  font-style: normal;
  margin: 0 auto 2rem;
}

.footer__contact__mail:link, .footer__contact__mail:visited {
  font-weight: 400;
  font-size: 1.4rem;
}

.footer__company {
  font-size: 1.4rem;
  padding: 5rem 0;
}

.footer__company__name {
  margin-bottom: 3rem;
  font-weight: 800;
  font-size: 1.98rem;
}

.footer__company__services {
  margin-bottom: 3rem;
  font-weight: 500;
}

.footer__company__services__ul__li {
  margin-bottom: .5rem;
}

.footer__company__services__ul__li:last-child {
  margin-bottom: 0;
}

.footer__company__place div {
  margin-bottom: .5rem;
}

.footer__company__place div:last-child {
  margin-bottom: 0;
}

.footer__company__place__tel a {
  color: #fff;
}

.talents_ul {
  max-width: 75rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.talents_ul_li {
  max-width: 25rem;
  margin-bottom: 5rem;
}

.talents_ul_li_a_img {
  margin-bottom: 1rem;
  padding: 0 4rem;
}

.talents_ul_li_a_sns {
  margin-bottom: 1rem;
}

.talents_ul_li_a_sns_a {
  display: inline-block !important;
}

.talents_ul_li_a_title {
  font-size: 1.62rem;
  font-weight: 500;
  color: white;
}

.talents_ul_li_a_detail {
  color: #fff;
  text-align: right;
}

.talents_ul_li_a_eng {
  font-size: 1rem;
  color: #959595;
  margin-bottom: 1.62rem;
}

.talent_cover {
  width: 100%;
  margin-bottom: 5rem;
}

.talent_name {
  font-size: 2.4rem;
  display: block;
  max-width: 50rem;
  margin: 0 auto 1rem;
}

.talent_sns {
  text-align: center;
  margin-bottom: 1.6rem;
}

.talent_sns_a, .talents_ul_li_a_sns_a {
  color: #fff !important;
  border-radius: 50%;
  background: #ff614c;
  font-size: 1.7rem;
  width: 3rem;
  height: 3rem;
  display: inline-block;
  margin-right: 1rem;
  position: relative;
  text-align: center;
}

.talent_sns_a:last-child, .talents_ul_li_a_sns_a:last-child {
  margin-right: 0;
}

.talent_sns_a_icon, .talents_ul_li_a_sns_a_icon {
  margin-left: 10%;
  vertical-align: middle;
}

.talent_content {
  width: calc(100% - 2rem);
  max-width: 50rem;
  margin: 0 auto 10rem;
}

.talent_gallery {
  margin: 0 auto;
  width: calc(100% - 2rem);
  max-width: 50rem;
}

.talent_gallery_ul_li {
  margin-bottom: 4.24rem;
}

.talent_insta_ul {
  margin: 0 auto;
  width: calc(100% - 2rem);
  max-width: 50rem;
}

.talent_insta_account {
  margin-bottom: 5rem;
  text-align: center;
}

.talent_insta_account a.artist_insta_account_link {
  font-size: 1.62rem;
  font-weight: 500;
  display: inline-block;
  border-bottom: 1px solid;
  color: #e83426;
}

.talent_insta_account .talent_insta_account_link_span {
  text-decoration: underline;
  color: white;
}

.talent_insta_ul a img {
  width: 100%;
}

.YouTubePopUp-Wrap {
  background: #000 !important;
}

.YouTubePopUp-Wrap .Video-PopUp-Content .vp-flex {
  background-color: #0a0a0a !important;
}
